import {Component, Input} from '@angular/core';
import {AuthClass} from '../../common/classes/auth.class';

@Component({
  selector: 'app-header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss']
})
export class HeaderBarComponent extends AuthClass {
  @Input() showLoginButton = false;
  @Input() showRegisterButton = false;
  @Input() showLogoutButton = false;

  constructor() {
    super();
  }
}
