import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {SharedModule} from './shared/shared.module';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {BASE_PATH} from '@ory/client/dist/base';
import {environment} from '../environments/environment';
import {
  MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatSnackBarConfig
} from '@angular/material/snack-bar';
import {SessionInterceptor} from './common/interceptors/session.interceptor';
import {ChaosApiModule, Configuration} from '@qernal/ngx-chaos-client';
import {MAT_CHIPS_DEFAULT_OPTIONS} from '@angular/material/chips';
import {COMMA} from '@angular/cdk/keycodes';

const snackbarConfig: MatSnackBarConfig = {
  duration: 3000,
  panelClass: '',
  horizontalPosition: 'center',
  verticalPosition: 'bottom'
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    ChaosApiModule.forRoot(() => new Configuration({
      basePath: environment.CHAOS_BASE_PATH,
      withCredentials: true
    })),
    AngularSvgIconModule.forRoot(),
    SharedModule
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        floatLabel: 'always',
        hideRequiredMarker: true,
        subscriptSizing: 'dynamic'
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionInterceptor,
      multi: true
    },
    {
      provide: BASE_PATH,
      useValue: environment.CHAOS_BASE_PATH
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: snackbarConfig
    },
    {
      provide: MAT_CHIPS_DEFAULT_OPTIONS,
      useValue: {
        separatorKeyCodes: [COMMA]
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
