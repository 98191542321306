import {AuthService} from '../services/auth.service';
import {inject} from '@angular/core';
import {LogoutFlow} from '@ory/client';
import {throwError} from 'rxjs';
import {UserProjectsService} from '../services/user-projects.service';
import {UserOrganisationsService} from '../services/user-organisations.service';
import {MatSnackBar} from '@angular/material/snack-bar';

export abstract class AuthClass {
  protected authService: AuthService = inject(AuthService);
  protected userOrganisationsService: UserOrganisationsService = inject(UserOrganisationsService);
  protected userProjectsService: UserProjectsService = inject(UserProjectsService);
  protected matSnackBar: MatSnackBar = inject(MatSnackBar);

  logout() {
    this.authService.logout().subscribe({
      next: (logoutFlow: LogoutFlow) => {
        sessionStorage.removeItem('activeOrganisationName');
        sessionStorage.removeItem('activeOrganisationID');
        sessionStorage.removeItem('activeProjectName');
        sessionStorage.removeItem('activeProjectID');
        this.userOrganisationsService.removeUserOrganisations();
        this.userProjectsService.removeUserProjects();
        window.location.href = logoutFlow.logout_url;
      },
      error: (error) => {
        this.matSnackBar.open('Error Logging Out');
        return throwError(() => console.error(error));
      }
    });
  }
}
