import {Injectable} from '@angular/core';
import {catchError, Observable, throwError} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {ListOrganisationResponse, OrganisationResponse, OrganisationsService} from '@qernal/ngx-chaos-client';

export interface UserOrganisation extends OrganisationResponse {
  active: boolean
}

@Injectable({
  providedIn: 'root'
})
export class UserOrganisationsService {
  public userOrganisationsObservable: Observable<UserOrganisation[]>;

  constructor(
    private organisationsService: OrganisationsService
  ) {
    this.userOrganisationsObservable = this.getOrganisations();
  }

  public getOrganisations(): Observable<UserOrganisation[]> {
    return this.organisationsService.organisationsList({
      before: 20,
      after: 0,
      size: 20
    }).pipe(
      map((listOrganisationResponse: ListOrganisationResponse) =>
        listOrganisationResponse.data.map((organisationResponse: OrganisationResponse, index: number) => {
          if (!sessionStorage.getItem('activeOrganisationID') && index === 0) {
            sessionStorage.setItem('activeOrganisationID', organisationResponse.id);
            sessionStorage.setItem('activeOrganisationName', organisationResponse.name);
          }
          return Object.assign(organisationResponse, {active: sessionStorage.getItem('activeOrganisationID') === organisationResponse.id}) as UserOrganisation;
        })
      ),
      shareReplay(),
      catchError((error: Error) =>
        throwError(() => error)
      )
    );
  }

  public getActiveOrganisation(): Observable<UserOrganisation> {
    return this.userOrganisationsObservable.pipe(
      map((userOrganisations: UserOrganisation[]): UserOrganisation => {
        const activeOrganisation: UserOrganisation | undefined = userOrganisations.find((userOrganisation: UserOrganisation) => userOrganisation.active);
        return activeOrganisation !== undefined ? activeOrganisation : userOrganisations[0]
      }),
      shareReplay(),
      catchError((error: Error) =>
        throwError(() => error)
      )
    )
  }

  public removeUserOrganisations(): void {
    this.userOrganisationsObservable.pipe(map(() => []));
  }
}
