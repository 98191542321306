import {inject, Injectable} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import {catchError, Observable} from 'rxjs';
import {Router} from '@angular/router';

@Injectable()
export class SessionInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const router: Router = inject(Router);
    return next.handle(request).pipe(
      catchError((error) => {
        if (request.url.includes('/auth/login') && request.url.includes('/auth/register')) {
          return next.handle(request);
        }
        if (error instanceof HttpErrorResponse) {
          if (error.status === 400 && (error.error?.error && error.error?.error?.id === 'session_already_available')) {
            router.navigate(['/auth', 'login'], {
              queryParams: {
                refresh: 'true'
              },
              onSameUrlNavigation: 'reload'
            }).then();
          }
          if (error.status === 401) {
            router.navigate(['/auth', 'login']).then();
          }
          if (error.status === 403) {
            router.navigate(['/auth', 'login'], {
              queryParams: {
                refresh: 'true'
              }
            }).then();
          }
          if (error.status === 410) {
            window.location.reload();
          }
          if (request.url.includes('/recovery') && error.status === 422) {
            router.navigate(['/settings', 'password']).then();
          }
          if (request.url.includes('/login') && error.status === 422) {
            router.navigate(['/auth', 'login-code']).then();
          }
          if (error.status === 500) {
            router.navigate(['/error', 'internal-server-error']).then();
          }
        }
        return next.handle(request);
      })
    );
  }
}
