import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {authGuard} from './common/guards/auth.guard';
import {environment} from '../environments/environment';
import {userOrganisationGuard} from './common/guards/user-organisation.guard';
import {userProjectsGuard} from './common/guards/user-projects.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/auth/login'
  },
  {
    path: 'dashboard',
    canActivate: [
      authGuard,
      userOrganisationGuard,
      userProjectsGuard
    ],
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    title: `Dashboard - ${sessionStorage.getItem('activeProjectName')} - ${sessionStorage.getItem('activeOrganisationName')} - ${environment.appName}`
  },
  {
    path: 'organisations',
    canActivate: [
      authGuard
    ],
    loadChildren: () => import('./organisations/organisations.module').then(m => m.OrganisationsModule),
    title: `Organisations - ${sessionStorage.getItem('activeProjectName')} - ${sessionStorage.getItem('activeOrganisationName')} - ${environment.appName}`
  },
  {
    path: 'projects',
    canActivate: [
      authGuard
    ],
    loadChildren: () => import('./projects/projects.module').then(m => m.ProjectsModule),
    title: `Projects - ${sessionStorage.getItem('activeProjectName')} - ${sessionStorage.getItem('activeOrganisationName')} - ${environment.appName}`
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'start',
    loadChildren: () => import('./start/start.module').then(m => m.StartModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [
      authGuard
    ],
    title: `Settings - ${sessionStorage.getItem('activeProjectName')} - ${sessionStorage.getItem('activeOrganisationName')} - ${environment.appName}`
  },
  {
    path: 'tokens',
    loadChildren: () => import('./tokens/tokens.module').then(m => m.TokensModule),
    canActivate: [
      authGuard,
      userOrganisationGuard,
      userProjectsGuard
    ],
    title: `Tokens - ${sessionStorage.getItem('activeProjectName')} - ${sessionStorage.getItem('activeOrganisationName')} - ${environment.appName}`
  },
  {
    path: 'secrets',
    loadChildren: () => import('./secrets/secrets.module').then(m => m.SecretsModule),
    canActivate: [
      authGuard,
      userOrganisationGuard,
      userProjectsGuard
    ],
    title: `Secrets - ${sessionStorage.getItem('activeProjectName')} - ${sessionStorage.getItem('activeOrganisationName')} - ${environment.appName}`
  },
  {
    path: 'hosts',
    loadChildren: () => import('./hosts/hosts.module').then(m => m.HostsModule),
    canActivate: [
      authGuard,
      userOrganisationGuard,
      userProjectsGuard
    ],
    title: `Hosts - ${sessionStorage.getItem('activeProjectName')} - ${sessionStorage.getItem('activeOrganisationName')} - ${environment.appName}`
  },
  {
    path: 'functions',
    loadChildren: () => import('./functions/functions.module').then(m => m.FunctionsModule),
    canActivate: [
      authGuard,
      userOrganisationGuard,
      userProjectsGuard
    ],
    title: `Functions - ${sessionStorage.getItem('activeProjectName')} - ${sessionStorage.getItem('activeOrganisationName')} - ${environment.appName}`
  },
  {
    path: 'error',
    loadChildren: () => import('./error/error.module').then(m => m.ErrorModule),
    title: `Error - ${sessionStorage.getItem('activeProjectName')} - ${sessionStorage.getItem('activeOrganisationName')} - ${environment.appName}`
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'error/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {enableTracing: false})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
