import {Injectable} from '@angular/core';
import {mergeMap, Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {
  ListProjectResponse,
  ProjectResponse,
  ProjectsService
} from '@qernal/ngx-chaos-client';
import {UserOrganisation, UserOrganisationsService} from './user-organisations.service';

export interface UserProject extends ProjectResponse {
  active: boolean
}

@Injectable({
  providedIn: 'root'
})
export class UserProjectsService {
  public userProjectsObservable: Observable<UserProject[]>;

  constructor(
    private projectsService: ProjectsService,
    private userOrganisationsService: UserOrganisationsService
  ) {
    this.userProjectsObservable = this.getProjects();
  }

  public getProjects(): Observable<UserProject[]> {
    return this.userOrganisationsService.getActiveOrganisation().pipe(
      mergeMap((activeOrg: UserOrganisation) => {
        return this.projectsService.organisationsProjectsList(activeOrg.id).pipe(
          map((listProjectResponse: ListProjectResponse) =>
            listProjectResponse.data.map((projectResponse: ProjectResponse, index: number) => {
              if (!sessionStorage.getItem('activeProjectID') && index === 0) {
                sessionStorage.setItem('activeProjectID', projectResponse.id);
                sessionStorage.setItem('activeProjectName', projectResponse.name);
              }
              return Object.assign(projectResponse, {active: sessionStorage.getItem('activeProjectID') === projectResponse.id}) as UserProject;
            })
          )
        );
      }),
      shareReplay()
    )
  }

  public getActiveProject(): Observable<UserProject> {
    return this.userProjectsObservable.pipe(
      map((userProjects: UserProject[]): UserProject => {
        const activeProject: UserProject | undefined = userProjects.find((userProject: UserProject) => userProject.active);
        return activeProject !== undefined ? activeProject : userProjects[0]
      }),
      shareReplay()
    )
  }

  public removeUserProjects(): void {
    this.userProjectsObservable.pipe(map(() => []));
  }
}
