<div class="header">
    <div class="logo">
        <a [routerLink]="['/dashboard']">
            <svg-icon src="assets/images/logo.svg" [svgStyle]="{ 'width.px':90 }"></svg-icon>
        </a>
    </div>
    <div class="buttons">
        <button mat-raised-button color="primary" [routerLink]="['/auth', 'login']" *ngIf="showLoginButton">Login</button>
        <button mat-raised-button color="primary" [routerLink]="['/auth', 'register']" *ngIf="showRegisterButton">Register</button>
        <button mat-raised-button color="primary" (click)="logout()" *ngIf="showLogoutButton">Logout</button>
    </div>
</div>
