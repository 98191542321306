<mat-sidenav-container class="sidenav-container">
    <mat-sidenav #drawer class="sidenav" fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="(isHandset$ | async) === false">
        <mat-toolbar class="logo">
            <a [routerLink]="['/dashboard']">
                <svg-icon src="assets/images/logo.svg" [svgStyle]="{ 'width.px':90 }"></svg-icon>
            </a>
        </mat-toolbar>
        <div class="nav-content">
            <div class="organisation-menu">
<!--                <ng-container *ngIf="!!(userSessionObservable | async)">-->
                    <mat-list>
                        <mat-divider></mat-divider>
                        <mat-list-item *ngIf="activeUserOrganisationObservable | async as activeUserOrganisation">
                            <div class="organisation-item">
                                <button mat-icon-button [routerLink]="['/organisations', 'edit']" [queryParams]="{id: activeUserOrganisation.id}">
                                    <fa-icon [icon]="faSitemap"></fa-icon>
                                </button>
                                <div class="organisation-container">
                                    <div class="organisation-label">Organisation</div>
                                    <div class="organisation-name">{{ activeUserOrganisation.name }}</div>
                                </div>
                                <div class="organisation-list">
                                    <ng-container *ngIf="organisationsListObservable | async as organisationsList">
                                        <ng-container *ngIf="organisationsList.length > 1">
                                            <button mat-icon-button [matMenuTriggerFor]="organisationsMenu" #organisationsMenuTrigger="matMenuTrigger">
                                                <fa-icon [icon]="faAngleRight"></fa-icon>
                                            </button>
                                            <mat-menu #organisationsMenu="matMenu">
                                                <ng-container *ngFor="let organisation of organisationsList; let i = index">
                                                    <button mat-menu-item *ngIf="!organisation.active" (click)="selectOrganisation($event, organisation)">
                                                        <span>{{ organisation.name }}</span>
                                                    </button>
                                                </ng-container>
                                            </mat-menu>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-list-item>
                        <mat-list-item *ngIf="activeUserProjectObservable | async as activeUserProject">
                            <div class="organisation-item">
                                <button mat-icon-button [routerLink]="['/projects', 'edit']" [queryParams]="{id: activeUserProject.id}">
                                    <fa-icon [icon]="faList"></fa-icon>
                                </button>
                                <div class="organisation-container">
                                    <div class="organisation-label">Project</div>
                                    <div class="organisation-name">{{ activeUserProject.name }}</div>
                                </div>
                                <div class="organisation-list">
                                    <ng-container *ngIf="projectsListObservable | async as projectsList">
                                        <ng-container *ngIf="projectsList.length > 1">
                                            <button mat-icon-button [matMenuTriggerFor]="projectsMenu" #projectsMenuTrigger="matMenuTrigger">
                                                <fa-icon [icon]="faAngleRight"></fa-icon>
                                            </button>
                                            <mat-menu #projectsMenu="matMenu">
                                                <ng-container *ngFor="let project of projectsList; let i = index">
                                                    <button mat-menu-item *ngIf="!project.active" (click)="selectProject($event, project)">
                                                        <span>{{ project.name }}</span>
                                                    </button>
                                                </ng-container>
                                            </mat-menu>
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </div>
                        </mat-list-item>
                    </mat-list>
<!--                </ng-container>-->
            </div>
            <div class="nav-menu">
                <mat-nav-list>
                    <mat-divider></mat-divider>
                    <mat-list-item [routerLink]="['/dashboard']" [routerLinkActive]="['active']">
                        <fa-icon [icon]="faHouse"></fa-icon>
                        <div class="menu-item-text">
                            Dashboard
                        </div>
                    </mat-list-item>
                    <mat-list-item [routerLink]="['/functions']" [routerLinkActive]="['active']">
                        <fa-icon [icon]="faDesktop"></fa-icon>
                        <div class="menu-item-text">
                            Functions
                        </div>
                    </mat-list-item>
                    <mat-list-item [routerLink]="['/secrets']" [routerLinkActive]="['active']">
                        <fa-icon [icon]="faUserSecret"></fa-icon>
                        <div class="menu-item-text">
                            Secrets
                        </div>
                    </mat-list-item>
                    <mat-list-item [routerLink]="['/hosts']" [routerLinkActive]="['active']">
                        <fa-icon [icon]="faEarthAmericas"></fa-icon>
                        <div class="menu-item-text">
                            Hosts
                        </div>
                    </mat-list-item>
                    <!--                    <mat-list-item [routerLink]="['/database']" [routerLinkActive]="['active']">-->
                    <!--                        <fa-icon [icon]="faServer"></fa-icon>-->
                    <!--                        Database-->
                    <!--                    </mat-list-item>-->
                    <!--                    <mat-list-item [routerLink]="['/marketplace']" [routerLinkActive]="['active']">-->
                    <!--                        <fa-icon [icon]="faStore"></fa-icon>-->
                    <!--                        Marketplace-->
                    <!--                    </mat-list-item>-->
                    <mat-list-item [routerLink]="['/settings', 'profile']" [routerLinkActive]="['active']">
                        <fa-icon [icon]="faCog"></fa-icon>
                        <div class="menu-item-text">
                            Settings
                        </div>
                    </mat-list-item>
                </mat-nav-list>
            </div>
            <div class="footer">
                <mat-nav-list>
                    <mat-divider></mat-divider>
                    <mat-list-item [routerLink]="['sitemap']" [routerLinkActive]="['active']">
                        Sitemap
                    </mat-list-item>
                    <mat-list-item [routerLink]="['help']" [routerLinkActive]="['active']">
                        Help
                    </mat-list-item>
                    <mat-list-item [routerLink]="['dev']" [routerLinkActive]="['active']">
                        Dev
                    </mat-list-item>
                    <mat-list-item [routerLink]="['docs']" [routerLinkActive]="['active']">
                        Docs
                    </mat-list-item>
                </mat-nav-list>
                <div class="copyright">
                    <fa-icon [icon]="faCopyright"></fa-icon>
                    Qernal
                    {{ dateYear }}
                </div>
                <div class="app-version">
                    v{{appVersion}}
                </div>
            </div>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <mat-toolbar color="primary">
            <button
                    type="button"
                    aria-label="Toggle sidenav"
                    mat-icon-button
                    (click)="drawer.toggle()"
                    *ngIf="isHandset$ | async">
                <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
            </button>
            <div class="header-container">
                <div class="left-container">
                    <mat-form-field appearance="outline" class="custom-field">
                        <input matInput placeholder="Search">
                        <mat-icon matPrefix>search</mat-icon>
                    </mat-form-field>
                </div>
                <div class="right-container">
                    <div class="blocks-container">
                        <button mat-icon-button aria-label="Blocks" disabled>
                            <fa-icon [icon]="faDisplay"></fa-icon>
                        </button>
                    </div>
                    <div class="notification-container">
                        <button mat-icon-button aria-label="Notifications" disabled>
                            <fa-icon [icon]="faBell"></fa-icon>
                        </button>
                    </div>
                    <div class="user-container">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="User Settings">
                            <fa-icon [icon]="faUser" [matMenuTriggerFor]="menu"></fa-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['/settings', 'profile']">Profile</button>
                            <button mat-menu-item [routerLink]="['/tokens']">Tokens</button>
                            <button mat-menu-item (click)="logout()">Logout</button>
                        </mat-menu>
                    </div>
                </div>
            </div>
        </mat-toolbar>
        <ng-content></ng-content>
    </mat-sidenav-content>
</mat-sidenav-container>
