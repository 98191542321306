import {NgModule} from '@angular/core';
import {SidenavComponent} from './sidenav/sidenav.component';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatInputModule} from '@angular/material/input';
import {HeaderBarComponent} from './header-bar/header-bar.component';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';


@NgModule({
  declarations: [
    SidenavComponent,
    HeaderBarComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FontAwesomeModule,
    AngularSvgIconModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule
  ],
  exports: [
    SidenavComponent,
    HeaderBarComponent
  ]
})
export class SharedModule {
}
