import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, switchMap} from 'rxjs';
import {LogoutFlow, Session} from '@ory/client';
import {environment} from '../../../environments/environment';
import {shareReplay} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userAuthSession: Observable<Session | undefined> = of(undefined);
  constructor(
    public httpClient: HttpClient
  ) {}

  public get userSession(): Observable<Session> {
    return this.userAuthSession.pipe(
      shareReplay(),
      switchMap((userSession: Session| undefined): Observable<Session> =>
        !userSession ? this.getSession() : of(userSession)
      )
    );
  }

  private getSession(): Observable<Session> {
    return this.httpClient.get<Session>(`${environment.KRATOS_BASE_PATH}/sessions/whoami`, {
      withCredentials: true
    }).pipe(
      shareReplay()
    );
  }

  logout(): Observable<LogoutFlow> {
    return this.httpClient.get<LogoutFlow>(`${environment.KRATOS_BASE_PATH}/self-service/logout/browser`, {
      withCredentials: true
    })
  }
}
