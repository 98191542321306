import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {inject} from '@angular/core';
import {catchError, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {
  AuthenticatorAssuranceLevel,
  Session,
  SessionAuthenticationMethod,
  VerifiableIdentityAddress
} from '@ory/client';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);
  return authService.userSession.pipe(
    map((session: Session): boolean => {
      const verifiable_addresses: VerifiableIdentityAddress[] | undefined = session.identity?.verifiable_addresses;
      if (verifiable_addresses && verifiable_addresses.length > 0) {
        const verifiable_address: VerifiableIdentityAddress | undefined = verifiable_addresses
          .find((verifiable_addresses: VerifiableIdentityAddress): boolean =>
            verifiable_addresses.value === session.identity?.traits.email
          );
        if (!verifiable_address || !verifiable_address.verified) {
          router.navigate(['/auth', 'verification']).then();
        }
      }
      const authenticator_assurance_level: AuthenticatorAssuranceLevel | undefined = session.authenticator_assurance_level;
      const sessionAuthenticationMethods: SessionAuthenticationMethod[] | undefined = session.authentication_methods;
      if (
        !state.url.includes('/settings') &&
        authenticator_assurance_level !== AuthenticatorAssuranceLevel.Aal2 &&
        (sessionAuthenticationMethods && sessionAuthenticationMethods.length > 0)
      ) {
        const sessionAuthenticationMethodTotp: SessionAuthenticationMethod | undefined = sessionAuthenticationMethods
          .find((sessionAuthenticationMethod: SessionAuthenticationMethod): boolean => {
            return sessionAuthenticationMethod.method === 'totp';
          });
        if (!sessionAuthenticationMethodTotp) {
          router.navigate(['/auth', 'new-login-code']).then();
        }
      }
      return true;
    }),
    catchError((error: Error) => {
      router.navigate(['/auth', 'login']).then();
      return throwError(() => error);
    })
  );
};
